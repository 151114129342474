import './App.css';
import './scrollbar.css';
import React, { Fragment, useContext, useEffect, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Userdata from './Context/userdataContext';
import Account, { socialMediaSummary } from './Context/socialMediaSummary';
import Inactive from './Context/SidebarInactive';
import Chart from './Context/ChartData';
import DirectLanding from './Components/DirectLanding/DirectLanding';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Bars } from 'react-loader-spinner'
import ForgotPassword from './Pages/forgotPassword/ForgotPassword';
import ResetPassword from './Pages/reset/ResetPassword';
// import Login from './RootPages/HomePageRoot.jsx'
const HomePageRoot = lazy(() => import('./RootPages/HomePageRoot'));
const Login = lazy(() => import('./Components/Login/Login'));
const Signup = lazy(() => import('./Components/Signup/Signup'));
const PublicProfile = lazy(() => import('./Components/PublicProfile/PublicProfile'));
const Navbar = lazy(() => import('./Components/Home/Navbar'));
const HomeRoot = lazy(() => import('./RootPages/HomeRoot'));
const AccountsRoot = lazy(() => import('./RootPages/AccountsRoot'));
const SettingsRoot = lazy(() => import('./RootPages/SettingsRoot'));
const AccountSummaryRoot = lazy(() => import('./RootPages/AccountSummaryRoot'));
const PageNotFound = lazy(() => import('./Components/PageNotFound/PageNotFound'));
const BarChart = lazy(() => import('./Components/Chart/BarChart'));
const DoughnutChart = lazy(() => import('./Components/Chart/DoughnutChart'));
const LineChart = lazy(() => import('./Components/Chart/LineChart'));
const Help = lazy(() => import('./Components/Help/Help'));
const AboutUs = lazy(() => import('./Components/AboutUs/AboutUs'));
const DirectLandingRoot = lazy(() => import('./RootPages/DirectLandingRoot'));
const PrivacyPolicy = lazy(() => import('./Components/T&C/PrivacyPolicy'));
const RefundCancellationShippingPolicy = lazy(() =>
  import('./Components/T&C/RefundCancellationPolicy')
);
const Tikktaproot = lazy(() => import('./Components/TikkTap/Tikktaproot'));
const ImageEditor = lazy(() => import('./Components/ImgaeEditor/ImageEditor'));
const Migration = lazy(() => import('./dataMigration/migration'));
const ThemeRoot = lazy(() => import('./RootPages/ThemeRoot'));
const Test = lazy(() => import('./dataMigration/Test'));
const ProductRoots = lazy(() => import('./Components/Cart/ProductRoots'));
const Checkout = lazy(() => import('./Components/Cart/Checkout'));
const ThanksPageRoot = lazy(() => import('./Components/Cart/ThanksPageRoot'));
const Payment = lazy(() => import('./Components/Cart/Payment'));
const ContactUs = lazy(() => import('./Components/ContactUs/ContactUs'));
const Compatibility = lazy(() => import('./Components/CompatibilityDevice/Compatibility'));
const Termsandconditions = lazy(() => import('./Components/T&C/TermsAndConditions'));
function App() {
  return (
    <div>
      <Userdata>
        <Account>
          <Chart>
            <Inactive>
              <Router>
                <Fragment>
                  <Suspense fallback={<div>
                    <Backdrop
                      open
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Set the background color with transparency
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                      }}
                    >
                      {/* <CircularProgress color="inherit" /> */}
                      <Bars
                        heigth="30"
                        width="30"
                        color='#0b2b66'
                        ariaLabel='loading' className='loaderbox' />
                    </Backdrop>
                  </div>}>
                    <Routes>
                      <Route exact path='/' element={<HomePageRoot />} />
                      <Route exact path='/login' element={<Login />} />
                      <Route exact path='/signup' element={<Signup />} />
                      <Route exact path='/forgot-password' element={<ForgotPassword />} />
                      <Route exact path='/reset-password/:token' element={<ResetPassword />} />
                      <Route exact path='/dashboard' element={<HomeRoot />} />
                      <Route exact path='/view/:id' element={<PublicProfile />} />
                      <Route exact path='/v/:id' element={<PublicProfile />} />
                      <Route exact path='/accounts' element={<AccountsRoot />} />
                      <Route exact path='/accounts/:account' element={<AccountSummaryRoot />} />
                      <Route exact path='/settings' element={<SettingsRoot />} />
                      <Route exact path='/theme' element={<ThemeRoot />} />
                      <Route exact path='/directlanding' element={<DirectLandingRoot />} />
                      
                      <Route exact path='/*' element={<PageNotFound />} />
                      <Route exact path='/404' element={<PageNotFound />} />
                      <Route exact path='/editor' element={<ImageEditor />} />
                      <Route exact path='/chart' element={<DoughnutChart />} />
                      <Route exact path='/chart1' element={<BarChart />} />
                      <Route exact path='/chart2' element={<LineChart />} />
                      <Route exact path='/help' element={<Help />} />
                      <Route exact path='/aboutus' element={<AboutUs />} />
                      <Route exact path='/login' element={<Login />} />
                      <Route exact path='/contactus' element={<ContactUs />} />
                      <Route exact path='/devicecompatibility' element={<Compatibility />} />
                      <Route exact path='/howitworks' element={<Tikktaproot />} />
                      <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
                      <Route exact path='/refundCancellationShippingPolicy' element={<RefundCancellationShippingPolicy />} />
                      <Route exact path='/Termsandconditions' element={<Termsandconditions />} />
                      <Route exact path='/product/:id' element={<ProductRoots />} />
                      <Route exact path='/checkout' element={<Checkout />} />
                      <Route exact path='/product/:id/checkout/:emailId' element={<Payment />} />
                      <Route exact path='/product/checkout/thankyou' element={<ThanksPageRoot />} />

                      <Route exact path='/migration' element={<Migration />} />
                      {/* <Route exact path='/test' element={<Test />} /> */}
                      {/* <Route exact path='/crud' element={<Crud />} /> */}
                      {/* <Route exact path='/nfc' element={<NFC />} /> */}
                    </Routes>
                  </Suspense>
                </Fragment>
              </Router>
            </Inactive>
          </Chart>
        </Account>
      </Userdata>
    </div>
  );
}

export default App;
