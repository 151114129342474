import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './DirectLanding.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux'
import { updateUserData } from '../../redux/userRedux'
import { publicRequest } from '../../requestMethods'
import { Button, TextField } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LoginImage from '../../Graphics/PasswordKey.svg'
function DirectLanding() {
    const dispatch = useDispatch()
    const [toggle, setToggle] = useState(false)
    const [directlandingUrl, setDirectlandingUrl] = useState('')
    const [directLanding, setDirectLanding] = useState(false)
    const email = useSelector((state) => state.user.email);
    const [newDirectLandingUrl, setNewDirectLandingUrl] = useState(null)
    let selectedIndex = useSelector((state) => state.user.usernameIndex);
    const user = useSelector((state) => state.user.userData);
    const DIRECT_LANDING = user[selectedIndex].directLanding;


    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()
    const [showLogin, setShowLogin] = useState(false);
    const [loading, setLoading] = useState(false)
    let _id = useSelector((state) => state.user._id);
    // const DIRECT_LANDING = selectedIndex !== null && user[selectedIndex].directLanding !== null ? user[selectedIndex].directLanding : {};
    const [userData, setUserData] = useState('')
  
    const navigate = useNavigate();
    useEffect(() => {
        if (user == null) {
            navigate({
                pathname: '/login',
            });
        } else {
            // setAccounts(user[selectedIndex].accounts)
          
            setUserData(user[selectedIndex])
            if (DIRECT_LANDING.status == true) {
              
                setToggle(true)
                setDirectLanding(true)
                setDirectlandingUrl(DIRECT_LANDING.link)

            }
            if (DIRECT_LANDING.status == false) {
                setDirectLanding(false)
                setDirectlandingUrl(DIRECT_LANDING.link)
                setToggle(false)
            }
        }
    }, [user, selectedIndex])


    const toggler = () => {
       
        if (toggle == true) {
            setToggle(false)
        } else {
            setToggle(true)

        }
    }

    const handleNotification = async () => {

        toast.success('Details updated', {
            position: "top-right",
            autoClose: 300,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: true,
            theme: "colored",
            // transition:"zoom",

        })

    }

    const handleSubmit = async () => {
        setSuccessMessage('')
        const updateData = {
            directLanding: {
                status: toggle ? "true" : "false",
                link: newDirectLandingUrl ? newDirectLandingUrl : userData.directLanding.link
            }

        }

        try {
            // const res = await axios.put(`http://localhost:5000/api/userData/${userData._id}`, updateData);
            const res = await publicRequest.axios.put(`/userData/${_id}/${userData._id}`, updateData, {
                withCredentials: true
            });
          
            dispatch(updateUserData(res.data));
            handleNotification()

        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
            if (error.response.data == `You're not authenticated!`) {
              
                if (email === null || email === undefined) {
                    navigate({
                        pathname: '/login',
                    });
                } else {

                    setShowLogin(true);
                }
            }
        }


    }
    const handleLogin = async () => {
        setErrorMessage('')
        try {

            const res = await publicRequest.post('/auth/login', { email: email, password: password }, {
                withCredentials: true
            })
            setShowLogin(false);
            handleSubmit()

        } catch (error) {
            
            setErrorMessage(error.message)
        }

    }
    return (
        <div className='directLandingPage'>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {showLogin && (
                <div className="login-panel">
                    <div className="login-container">

                        {/* <div className='blur ' style={{
                            backgroundColor: "rgb(238 210 255)",
                            top: '2%',
                            width: '11rem',
                            height: '7rem',
                            left: '12vh',
                            overflow: 'hidden'
                        }}> </div> */}

                        <img src={LoginImage} alt="" />
                        <span><ReportProblemIcon />Enter Your password!</span>
                        <TextField
                            type="password"
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button variant="contained" onClick={handleLogin}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
            <div className="directLandingPage_lineOne">


                <div className="directLandingPage_lineOne-title">
                    <h5>Direct Landing Page </h5>


                </div>
                <div className="directLandingPage_lineOne-toggleButton">
                    {/* <Switch checked={toggle} onClick={toggler} /> */}
                    <Switch
                        checked={toggle}
                        onChange={toggler}

                    />



                </div>
            </div>

            <h6>Paste your direct landing page link </h6>
            <div className="directLandingPage_form">

                <div className="directLandingPage_form-inputBox">

                    <input name="name" className='fieldtextbox' type="text" autoComplete="off" Value={directlandingUrl}
                        onChange={(e) => setNewDirectLandingUrl(e.target.value)} />


                </div>


            </div>
            <div className="directLandingPage_button">
                <button onClick={handleSubmit} className='primaryButton'>Update</button>

            </div>

        </div>
    )
}

export default DirectLanding