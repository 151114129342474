import React,{ createContext,useState } from "react";

export const chartData = createContext(null)

function Chart({children}){
    const [userAccountVisitLog, setUserAccountVisitLog] = useState()
    const [accountVisit, setAccountVisit] = useState({
        facebook:0,
        instagram:0,
        whatsApp:0,
        youtube:0,
        call:0,
        website:0,
        telegram:0,
        twitter:0,
        mail:0,
        linkedin:0,
    })

    return(
        <chartData.Provider value={{userAccountVisitLog,setUserAccountVisitLog,accountVisit, setAccountVisit}}>
            {children}
        </chartData.Provider>
    )
}
export default Chart