import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        selectedProduct: null,
        cart: [],
    },
    reducers: {
        allProducts: (state, action) => {
            state.products = action.payload
           
        },
        selectedProduct: (state, action) => {
            state.selectedProduct = action.payload
        },
        
    },
});

export const { allProducts, selectedProduct } = cartSlice.actions;
export default cartSlice.reducer;
