import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    usernameIndex: null,
    isFetching: false,
    error: false,
    _id: null
  },

  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = false;

    },
    loginSuccess: (state, action) => {
      // console.log("Login success working");
      state.isFetching = false;
      state.userData = action.payload.userData;
      state._id = action.payload._id;
      state.email = action.payload.email;
      // state.usernameIndex = 0;
    },
   
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;

    },
    logout: (state) => {
      state.isFetching = false;
      state.userData = null;
      state.usernameIndex = null;
      state.email = null;
      state._id = null;
    },
    updateUserDataAccounts: (state, action) => {
      const index = state.usernameIndex;
      state.isFetching = false;
      state.userData[index].accounts = action.payload;
    },
    updateUserData: (state, action) => {
      const index = state.usernameIndex;
      state.isFetching = false;
      state.userData[index] = action.payload;
    },
    changeUsernameIndex: (state, action) => {

      state.usernameIndex = action.payload;
    },

  },
});

export const { loginStart, loginFailure, loginSuccess, logout, updateUserData, updateUserDataAccounts, changeUsernameIndex } = userSlice.actions;
export default userSlice.reducer;

