import React, { useState } from 'react'
import './forgotPassword.scss'
import { publicRequest } from '../../requestMethods'
import LoginPageImage from '../../Graphics/LoginPage2.svg'
import PieLogo from '../../Graphics/LoginPage4.svg'
import NFCLogo from '../../Graphics/LoginPage5.svg'
import mailSent from '../../Graphics/mailSent.svg'


const ForgotPassword = () => {

  const [email, setEmailID] = useState()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const handleForgotPassword = async () => {
    if (email) {

      try {
        const res = await publicRequest.post('/auth/forgot-password', { email: email })
       
        setSuccess(true)
        setError(null)
      } catch (error) {
       
        setError(error.response.data.message)
      }
    } else {
      setError("Enter valid Email ID")
    }
  }
  return (
    <div className='forgotPassword'>
      <div className="left">
        <img src={LoginPageImage} alt="Forgot Password Image" />
      </div>
      <div className="right">
        <div className="container">
          <div className="heading">

            <h1>Forgot Password</h1>
          </div>
          <div className="description">
            {success ?
              <img src={mailSent} alt="Mail sent Image" />
              :
              <h6>Please enter your email address. You will receive a link to create a new Password via email.</h6>}
          </div>
          <div className="input">
            {success ?
              <a>Password reset link has been sent!  </a>
              :
              <input type="text" placeholder='Enter Your email id' onChange={(e) => setEmailID(e.target.value)} />
            }

          </div>
          <div className="button">

            {!success && <button disabled={success} onClick={() => {
              handleForgotPassword()
            }}>Send</button>}
          </div>
          <div className='image'>
           {!success&& <img src={LoginPageImage} alt="" />}
          </div>


          <div className="errorMessage">
            {error && <a>{error}</a>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default ForgotPassword