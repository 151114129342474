import React, { createContext, useState } from "react";

export const socialMediaSummary = createContext('')
export const ALL_PRODUCT = createContext('')
export const ORDER_DETAILS = createContext('')
// export const socialMediaSummary = createContext('')

function Account({ children }) {

    const [clickedAccount, setClickedAccount] = useState()
    const [userUID, setUserUID] = useState()
    const [channel, setChannel] = useState('')
    const [userDetails, setUserDetails] = useState()

    const [order_details, setOrder_details] = useState('')
    const [buyer_details, setBuyer_details] = useState([])

    const [allProduct, setAllProduct] = useState()
    const [selectedProduct, setSelectedProduct] = useState()

    return (
        <socialMediaSummary.Provider value={{ clickedAccount, setClickedAccount, userUID, setUserUID, channel, setChannel, userDetails, setUserDetails }}>
            <ALL_PRODUCT.Provider value={{ allProduct, setAllProduct, selectedProduct, setSelectedProduct }}>
                <ORDER_DETAILS.Provider value={{ order_details, setOrder_details, buyer_details, setBuyer_details }}>

                    {children}
                </ORDER_DETAILS.Provider>

            </ALL_PRODUCT.Provider>
        </socialMediaSummary.Provider>
    )
}
export default Account