import React ,{ createContext,useState } from "react";

export const UserdataContext =createContext(null)

export default function Userdata ({children}){
    const [userSummary,setUserSummary] =useState(null)
    
    return(
        <UserdataContext.Provider value={{userSummary,setUserSummary}}>
            {children}
        </UserdataContext.Provider>
    )
}
