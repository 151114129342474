import React, { useState } from 'react'
import './resetPassword.scss'
import { publicRequest } from '../../requestMethods'
import { useLocation, useNavigate } from 'react-router-dom'

const Reset = () => {
  const location = useLocation()
  const token = location.pathname.split("/")[2]
  const [password, setPassword] = useState()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate("");
  const handleResetPassword = async () => {
    if (password) {

      try {
        const res = await publicRequest.post(`/auth/reset-password/${token}`, { password: password })
        
        setSuccess(true)
        setError(null)
      } catch (error) {
        setError(error.response.data.message)
      }
    } else {
      setError("Enter valid password")
    }
  }
  return (
    <div className='resetPassword'>
      <div className="left">

      </div>
      <div className="right">
        <div className="container">
          <div className="heading">

            <h1>Reset Password</h1>
          </div>
          <div className="description">
            {!success&&<h6>Please enter your new password.</h6>}
          </div>
          <div className="input">
            {success ?
              <a>Your password has been reset!  </a>
              :
              <input type="password" placeholder='New Password' onChange={(e) => setPassword(e.target.value)} />
            }
          </div>
          <div className="button">
            {success ? <button onClick={()=>{
              navigate({
                pathname:'/login',
              })
            }}>Login Now</button>
              : <button onClick={() => {
                handleResetPassword()
              }}>Reset</button>}
          </div>
          <div className="errorMessage">
            {error && <a>{error}</a>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Reset
