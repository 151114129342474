import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'antd/dist/antd.css'
import { Provider } from 'react-redux'
import { store, persistor } from '../src/redux/store'
import { PersistGate } from 'redux-persist/es/integration/react';
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);


reportWebVitals();
